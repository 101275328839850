import React from 'react'
import { Link, graphql } from 'gatsby'
import { CalendarBlank } from 'phosphor-react'
import { BasicLayout } from '../layouts/basic'
import { SEOMeta } from '../components/SEOMeta'
import { MDXContent } from '../components/MDXContent'

const ReleaseNotes = ({
  location,
  data: {
    allMdx: { nodes: releaseNotes },
  },
}) => {
  return (
    <BasicLayout>
      <SEOMeta title="Release notes" path={location.pathname} />
      <div className="mx-auto max-w-4xl">
        <main className="mx-auto p-6 pb-12 max-w-6xl text-text-primary-light text-base leading-relaxed">
          <header className="md:grid md:grid-flow-col md:grid-cols-4 md:my-16">
            <h1 className="text-text-primary-light text-5xl font-extrabold tracking-tight leading-tight md:col-span-3 md:col-start-2">
              Release notes
            </h1>
          </header>
          <section className="mt-6 space-y-6">
            {releaseNotes.map((releaseNote) => (
              <article
                key={releaseNote.id}
                className="py-6 md:grid md:grid-flow-col md:grid-cols-4"
              >
                <div className="hidden md:flex md:col-start-1 md:row-start-1 md:items-center md:self-start md:mt-3 md:text-text-secondary-light md:text-sm md:space-x-1">
                  <CalendarBlank className="flex-shrink-0" />
                  <time>{releaseNote.frontmatter.date}</time>
                </div>
                <div className="md:col-span-3 md:col-start-2">
                  <h1 className="mt-4 text-text-primary-light text-3xl font-extrabold tracking-tight leading-tight md:mt-0">
                    <Link to={releaseNotePath(releaseNote)}>
                      {releaseNote.frontmatter.title}
                    </Link>
                  </h1>
                  <MDXContent>{releaseNote.body}</MDXContent>
                </div>
              </article>
            ))}
          </section>
        </main>
      </div>
    </BasicLayout>
  )
}

const releaseNotePath = (post) => `/${post.fields.source}${post.fields.slug}`

export const query = graphql`
  query {
    allMdx(
      filter: {
        frontmatter: { published: { eq: true } }
        fields: { source: { eq: "release-notes" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        body
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
          authors
        }
        fields {
          source
          slug
        }
      }
    }
  }
`

export default ReleaseNotes
